<template>
  <b-dropdown :variant="`blue-button text-white ${custom_class_dropdown}`" menu-class="w-100 p-0" :class="`w-100 blue-button text-white dropdown-add-influencer-action`" no-caret>
    <template #button-content>
      <feather-icon icon="PlusIcon" size="18"></feather-icon>
      <span class="line-height">{{ $t('search.add_to') }}...</span>
    </template>
    <b-dropdown-header>
      <div v-if="!show_add_to.list && !show_add_to.campaign">
        <p class="text-uppercase color-gray-title-add m-0 p-1">{{ $t('search.add_to') }}</p>
        <b-button-group vertical class="w-100 button-group-add-influencer-action">

          <b-button @click.stop="show_add_to.list = true">
            <div class="d-flex align-items-center"><feather-icon icon="ListIcon" class="img-icon-action mr-1"></feather-icon> {{$t('search.list_view')}}</div>
            <feather-icon icon="ChevronRightIcon" class="img-icon-action"></feather-icon>
          </b-button>
          <b-button @click.stop="show_add_to.campaign = true">
            <div class="d-flex align-items-center">
              <b-img :src="require('@/assets/images/svg/campaign_int_black.svg')" class="img-icon-action mr-1"></b-img> {{ $t('search.campaign')}}
            </div>
            <feather-icon icon="ChevronRightIcon" class="img-icon-action"></feather-icon>
          </b-button>
        </b-button-group>
      </div>
      <div v-if="show_add_to.list">
        <div class="p-1 border-bottom cursor-pointer" @click.stop="show_add_to.list = false">
          <h5 class="f-16"><feather-icon icon="ArrowLeftIcon" size="20"></feather-icon> {{ $t('search_preview.addList') }}</h5>
        </div>
        <p class="text-uppercase color-gray-title-add m-0 p-1">{{ $t('search_preview.allList') }}</p>
        <div v-if="!loaded_service_add" class="p-1">
          <b-skeleton class="p-1"></b-skeleton>
        </div>
        <div class="p-1 container-options-list-ul" v-else>
          <div
            v-for="(list, index) in lists" 
            :key="index" 
            :class="`avenir-medium w-100 dropitem-add-campaign ${list.with_influencer ? 'dropitem-add-campaign-disabled' : ''}`" 
            @click="list.with_influencer ? '' : addToList(list.uuid, index)"
          >
            <div class="d-flex justify-content-between container-drop-items">
              <span class="d-block drop-text"> {{list.object_name}}</span>
              <feather-icon 
                :icon="list.with_influencer ? 'CheckCircleIcon' : 'PlusIcon'" 
                :class="list.with_influencer ? 'success-color-icon' : 'secondary-color-icon'">
              </feather-icon>
              <b-spinner class="spinner-dropdown-item d-none" :ref="`spinner-dropdown-${index}`"></b-spinner>
            </div>
          </div>
          <div v-if="lists.length === 0" class="avenir-medium p-0">
            {{ $t('lists.noLists') }}
          </div>
        </div>
      </div>
      <div v-if="show_add_to.campaign">
        <div class="p-1 border-bottom cursor-pointer" @click.stop="show_add_to.campaign = false">
          <h5 class="f-16"><feather-icon icon="ArrowLeftIcon" size="20"></feather-icon> {{ $t('search_preview.addCampaign') }}</h5>
        </div>
        <p class="text-uppercase color-gray-title-add m-0 p-1">{{ $t('search_preview.allCampaigns') }}</p>
        <div v-if="!loaded_service_add" class="p-1">
          <b-skeleton class="p-1"></b-skeleton>
        </div>
        <div class="p-1 container-options-list-ul" v-else>
          <div
            v-for="(campaign, index) in campaigns" 
            :key="index" 
            :class="`avenir-medium w-100 dropitem-add-campaign ${campaign.with_influencer ? 'dropitem-add-campaign-disabled' : ''}`" 
            @click="campaign.with_influencer ? '' : addToCampaign(campaign.uuid, index)"
          >
            <div class="d-flex justify-content-between container-drop-items">
              <span class="d-block drop-text"> {{campaign.object_name}}</span>
              <feather-icon 
                :icon="campaign.with_influencer ? 'CheckCircleIcon' : 'PlusIcon'" 
                :class="campaign.with_influencer ? 'success-color-icon' : 'secondary-color-icon'">
              </feather-icon>
              <b-spinner class="spinner-dropdown-item d-none" :ref="`spinner-dropdown-c-${index}`"></b-spinner>
            </div>
          </div>
          <div v-if="campaigns.length === 0" class="avenir-medium p-0">
            {{ $t('campaigns.noCampaigns') }}
          </div>
        </div>
      </div>
    </b-dropdown-header>
  </b-dropdown>    
</template>

<script>
import { 
  BDropdownHeader,
  BDropdown,
  BSpinner,
  BSkeleton,
  BButton,
  BButtonGroup,
  BImg,
} from 'bootstrap-vue';
import search_services from '@/services/search';
import lists_services from '@/services/lists';
import service_campaigns from '@/services/campaign';
import service_profile from '@/services/others';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');


export default {
  name: 'addCampaignAndList',

  components: {
    BDropdownHeader,
    BDropdown,
    BSpinner,
    BSkeleton,
    BButton,
    BButtonGroup,
    BImg,
  },
  data () {
    return {
      show_add_to: {list: false, campaign: false},
      lists: [],
      campaigns: [],
      loaded_service_add: false,
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    custom_class_dropdown: {
      type: String,
      default: ''
    }
  },
  created () {
  },
  methods: {
    getInfluenerInLists() {      
      search_services.getInfluencerIn(this.data.username, this.data.network , 'lists').then((response) => {
        this.lists = response.results
        this.loaded_service_add = true;
      });
    },
    getInfluencersInCampaign() {
      search_services.getInfluencerIn(this.data.username, this.data.network, 'campaign').then((response) => {
        this.campaigns = response.results;
        this.loaded_service_add = true;
      });
    },
    addToCampaign(campaign_uuid, index_spinner) {
      this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.remove('d-none');
      this.addInfluencerToCampaign(campaign_uuid)
      setTimeout(() => {
        this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.add('d-none');
        this.campaigns.find((item) => item.uuid === campaign_uuid).with_influencer = true;
      }, 500);
    },
    addInfluencerToCampaign(campaign_uuid) {
      const form = new FormData()
      form.set('username', this.data.username);
      form.set('network', this.data.network);
      form.set('image', this.data.image);
      form.set('followers', this.data.followers);
      service_campaigns.addInfluncerv3(campaign_uuid, form).then((e) => {
        if (e.status === 400) {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              props: {
                title: this.$t('campaigns.errorInfluencer'),
                icon: 'BellIcon',
                text: e.data.response.message,
                variant: 'danger'
              }
            },
            {
              position: 'top-left'
            })
          })
        } else if (e.status === 500) {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              props: {
                title: this.$t('campaigns.errorInfluencer'),
                icon: 'BellIcon',
                text: e.data.response.message,
                variant: 'danger'
              }
            },
            {
              position: 'top-left'
            })
          })
        } else {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              props: {
                title: this.$t('search.added'),
                icon: 'CheckCircleIcon',
                text: this.$t('search.hasBeenAdded'),
                variant: 'success'
              }
            })
          })

          service_profile.getMaxViews()
            .then(response => {
              this.$root.$emit('change_max_views', response.max_views)
            })
        }
      });
    },
    addToList(list_uuid, index_spinner) {
      this.$refs[`spinner-dropdown-${index_spinner}`][0].classList.remove('d-none');

      lists_services.bulkAddInfluencer(list_uuid, {influencers: [this.getFormatAddInfluencer()]}).then(() => {
        setTimeout(() => {
          this.lists.find((item) => item.uuid === list_uuid).with_influencer = true;
          this.$refs[`spinner-dropdown-${index_spinner}`][0].classList.add('d-none');
        }, 500)
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: "top-right",
            props: {
              title:  this.$t('lists.addinfluencer'),
              icon: "CheckCircleIcon",
              variant: "success",
              text: this.$t('lists.addlist')
            }
          })
        })
      })
    },
    
    getFormatAddInfluencer() {
      return {
        influencer: this.data.username,
        network:this.data.network,
        followers: this.data.followers,
        image: this.data.username.avatar
      }
    },
  },
  watch: {
    'show_add_to.list'(value) {
      if (value) {
        this.loaded_service_add = false;
        this.getInfluenerInLists();
      }

    },
    'show_add_to.campaign'(value) {
      if (value) {
        this.loaded_service_add = false;
        this.getInfluencersInCampaign();
      }
    }
  }
}
</script>

<style lang="scss">
.dropdown-add-influencer-action {
  .line-height {
    line-height: 17px;
    margin-left: 0.5em;
  }
  .dropdown-header {
    padding: 0px;

    .button-group-add-influencer-action {
      button {
        background-color: white !important;
        border: none !important;
        color: #404452 !important;
        display: flex;
        justify-content: space-between;
        padding: 10px 12px 10px 20px !important;
        font-family: 'avenir-medium' !important;
        &:hover {
          background-color: #F5F8FA !important;
          box-shadow: none !important;
        }

        .img-icon-action {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.dropitem-add-campaign {
  padding: 0.5em;
}
.dropitem-add-campaign:hover {
  background-color: #EFF3F6;
  cursor: pointer;
}
.dropitem-add-campaign-disabled {
  color: #ccc;
}
.dropitem-add-campaign-disabled:hover {
  background-color: transparent;
  cursor: default;
}
.container-options-list-ul {
  max-height: 300px;
  overflow-y: scroll;
}
.success-color-icon {
  color: green;
}
.secondary-color-icon {
  color: #718096;
}
.color-gray-title-add {
  color: #687385;
  font-size: 12px;
}
.container-drop-items {
  // width: 250px;
  width: 100%;

  .drop-text {
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.container-drop-items > a:hover {
  background-color: #ccc !important;
}
.spinner-dropdown-item {
  position: absolute;
  right: 19px;
  background-color: white;
  height: 16px;
  width: 16px;
  color: #ccc;
}
</style>